import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Input, notification } from "antd";
import { Formik } from "formik";
import React, { Component } from "react";
import api from "../services/api";
import "./auth.modules.css";
import logo from "./logo.png";

export default class SignIn extends Component {
  state = { loading: false };

  render() {
    return (
      <div className={`center-screen signIn`}>
        <div className="form-container">
          <div className="logo-container">
            <img className="logo-image" src={logo} alt="Logo" />
          </div>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            onSubmit={async (values, actions) => {
              this.setState({ loading: true });
              const user = await api
                .post("/login", {
                  email: values.email,
                  password: values.password,
                })
                .catch((e) => {
                  if (e.response.status === 401) {
                    notification["error"]({
                      message: "Credenciais inválidas",
                      description: "Verifique as credenciais fornecidas",
                    });
                    actions.setSubmitting(false);
                    this.setState({ loading: false });
                    return;
                  }
                });

              if (user) {
                localStorage.setItem(
                  "@infleet-reports/user",
                  JSON.stringify(user.data)
                );
                this.props.handleCurrentUser(user.data);
              }
            }}
            render={({
              values,
              errors,
              status,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="form-fields">
                  <Input
                    name="email"
                    onChange={handleChange}
                    size="large"
                    style={{ marginBottom: "1rem" }}
                    placeholder="Usuário"
                    prefix={
                      <UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                  />
                  <Input.Password
                    name="password"
                    onChange={handleChange}
                    size="large"
                    style={{ marginBottom: "1rem" }}
                    placeholder="Senha"
                    prefix={
                      <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                  />
                  <button type="submit" className="button-login">
                    {this.state.loading ? "Entrando..." : "Entrar"}
                  </button>
                </div>
              </form>
            )}
          />
        </div>
      </div>
    );
  }
}
