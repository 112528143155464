interface SummaryReportCalculation {
  totalDistance: string;
  [key: string]: any;
}

export default function sumTotalDistance(
  summaryReport: SummaryReportCalculation[]
) {
  return summaryReport
    .reduce((sum: number, registry) => sum + Number(registry.totalDistance), 0)
    .toFixed(2);
}
