import { Position } from "../../types";
import { KNOTS_TO_KMH_UNIT } from "../../constants";

export const calculateMeanSpeed = (positions: Position[]) => {
  const meanSpeed = (
    (positions
      .map((position: Position) => position.speed)
      .reduce((total: number, speed: number) => total + speed, 0) *
      KNOTS_TO_KMH_UNIT) / // Multiply by 1.852 to transform knots into km/h
    positions.length
  ).toFixed(2);

  return meanSpeed;
};
