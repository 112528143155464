import React from "react";
import ReactDOM from "react-dom";
import "./global.css";
import "antd/dist/antd.css";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import { ConfigProvider } from "antd";
import pt_BR from "antd/es/locale/pt_BR";
import moment from "moment";
import "moment/locale/pt-br";

moment.locale("pt-br")

ReactDOM.render(
  <ConfigProvider locale={pt_BR}>
    <App />
  </ConfigProvider>,
  document.getElementById("root")
);
registerServiceWorker();
