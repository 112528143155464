import { Position } from "../../types";

export const formatHorimeter = (positions: Position[]) => {
  const positionLastIndex = positions.length - 1;

  const horimeter = JSON.parse(positions[positionLastIndex].attributes).hours;

  const hours = Math.floor(horimeter / 3600000);
  const minutes = Math.floor((horimeter / 60000) % 60);

  const formattedValue = `${hours} h e ${minutes} min`;

  return formattedValue;
};
