import { Position } from "../../types";

export type Units = "miles" | "kilometers" | "degrees" | "radians";

export function getDistance(
  pointA: number[],
  pointB: number[],
  units: Units = "kilometers"
): number {
  // http://en.wikipedia.org/wiki/Haversine_formula
  // http://www.movable-type.co.uk/scripts/latlong.html
  const dLat = degreesToRadians(pointB[1] - pointA[1]);
  const dLon = degreesToRadians(pointB[0] - pointA[0]);
  const lat1 = degreesToRadians(pointA[1]);
  const lat2 = degreesToRadians(pointB[1]);

  const a =
    Math.pow(Math.sin(dLat / 2), 2) +
    Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  let R = 6373;

  switch (units) {
    case "miles":
      R = 3960;
      break;
    case "kilometers":
      R = 6373;
      break;
    case "degrees":
      R = 57.2957795;
      break;
    case "radians":
      R = 1;
      break;
  }

  var distance = R * c;
  return distance;
}

export function degreesToRadians(degrees: number): number {
  return (degrees * Math.PI) / 100;
}

export default function calculateTotalDistance(positions: Position[]): number {
  const distances = positions.map((position, index, arr) => {
    if (index === 0) return 0;

    const previousPosition = arr[index - 1];

    const distance =
      JSON.parse(position.attributes)?.totalDistance -
      JSON.parse(previousPosition.attributes)?.totalDistance;

    if (distance > 100000 || distance < 0) return 0; // value in meters

    return distance;
  });
  const sumDistances = distances.reduce((a, b) => a + b, 0) / 1000; // transform to km

  return sumDistances;
}
