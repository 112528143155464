export const formatMillisecondsToReadableString = (
  milliseconds: number | undefined
) => {
  if (!milliseconds) return 0;

  const hours = Math.floor(milliseconds / 3600000);
  const minutes = Math.floor((milliseconds / 60000) % 60);

  const formattedValue = `${hours} h e ${minutes} min`;

  return formattedValue;
};
