import { groupBy, map } from "lodash";
import moment from "moment";
import { KNOTS_TO_KMH_UNIT } from "../../constants";
import { GroupedPosition, Position } from "../../types";
import { calculateCountTimeOverspeed } from "./calculate-count-overspeed";
import { calculateMaxSpeed } from "./calculate-max-speed";
import { calculateMeanSpeed } from "./calculate-mean-speed";
import calculateTotalDistance from "./calculate-total-distance";
import { formatHorimeter } from "./format-horimeter";
import { formatMillisecondsToReadableString } from "./format-milliseconds-to-readable-string";
import getTotalTimeWithIgnitionOnFromPositions from "./get-total-time-with-ignition-on-from-positions";

export const calculateSummaryReport = (positions: Position[]) => {
  const groupsByDate = groupBy(positions, (position: Position) =>
    moment(position.fixtime).startOf("day").format()
  );

  const dateWithPositions = map(
    groupsByDate,
    (positions: Position[], date: string) => ({
      date,
      positions,
    })
  );

  const report = dateWithPositions.map(
    (dateWithPositions: GroupedPosition, index: number) => {
      const date = moment(dateWithPositions["date"]).format("DD/MM/YY");
      const positions: Position[] = dateWithPositions["positions"].filter(
        (position: Position, index, arr) => {
          if (position.speed * KNOTS_TO_KMH_UNIT <= 130) {
            return true;
          }

          if (index === 0 && position.speed * KNOTS_TO_KMH_UNIT >= 200) {
            return false;
          }

          if (index === 0) {
            return true;
          }

          const previousPosition = arr[index - 1];

          return (
            previousPosition.speed * KNOTS_TO_KMH_UNIT + 35 >=
              position.speed * KNOTS_TO_KMH_UNIT &&
            previousPosition.speed !== position.speed
          );
        }
      );

      const positionsWithIgnition = positions.map((position) => ({
        ...position,
        ignition: JSON.parse(position.attributes).ignition,
      }));

      const positionLastIndex = positions.length - 1;

      const meanSpeed = calculateMeanSpeed(positions);
      const maxSpeed = calculateMaxSpeed(positions);
      const horimeter = formatHorimeter(positions);
      const operationTimeMilliseconds = getTotalTimeWithIgnitionOnFromPositions(
        positionsWithIgnition
      );

      const countTimeOverspeed = formatMillisecondsToReadableString(
        calculateCountTimeOverspeed(positions, 90)
      );

      const finalHours =
        JSON.parse(positions[positionLastIndex].attributes).hours / 3600000;

      const initialHours = JSON.parse(positions[0].attributes).hours / 3600000;

      const finalDistance =
        JSON.parse(positions[positionLastIndex].attributes).totalDistance /
        1000;

      const initialDistance =
        JSON.parse(positions[0].attributes).totalDistance / 1000;

      const totalDistance = calculateTotalDistance(positions);

      // const totalDistanceTraccar =
      //   finalDistance - initialDistance < 0
      //     ? 0
      //     : finalDistance - initialDistance;

      return {
        number: index + 1,
        date,
        initialHours: initialHours ? initialHours.toFixed(2) : "-",
        finalHours: finalHours ? finalHours.toFixed(2) : "-",
        initialDistance: initialDistance ? initialDistance.toFixed(2) : "-",
        finalDistance: finalDistance ? finalDistance.toFixed(2) : "-",
        totalDistance: totalDistance.toFixed(2),
        meanSpeed,
        maxSpeed,
        horimeter,
        countTimeOverspeed,
        operationTime: formatMillisecondsToReadableString(
          operationTimeMilliseconds
        ),
        totalTime: operationTimeMilliseconds,
      };
    }
  );

  return report;
};
