import moment from "moment";
import { KNOTS_TO_KMH_UNIT } from "../../constants";
import { Position } from "../../types";

export const calculateCountTimeOverspeed = (
  positions: Position[],
  limitKmh: number
) => {
  return positions
    .map((position, i) => {
      if (i === 0) return 0;

      const prevPosition = positions[i - 1];

      if (prevPosition.speed * KNOTS_TO_KMH_UNIT >= limitKmh) {
        return Math.abs(
          moment
            .duration(
              moment(position.fixtime).diff(moment(prevPosition.fixtime))
            )
            .asMilliseconds()
        );
      }

      return 0;
    })
    .reduce((total, value) => total + value, 0);
};
