import moment from "moment";
import { PositionWithIgnition } from "../../types";

export default function getTotalTimeWithIgnitionOnFromPositions(
  positions: PositionWithIgnition[]
): number | undefined {
  const hasIgnition = positions.some(
    ({ ignition }) => typeof ignition !== "undefined" && ignition !== null
  );

  if (!hasIgnition) return void 0;

  return positions
    .map((position, i) => {
      if (i === 0) return 0;

      const prevPosition = positions[i - 1];

      if (prevPosition.ignition === true) {
        return Math.abs(
          moment
            .duration(
              moment(position.fixtime).diff(moment(prevPosition.fixtime))
            )
            .asMilliseconds()
        );
      }

      return 0;
    })
    .reduce((total, value) => total + value, 0);
}
