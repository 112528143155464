import React, { Component } from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import { ClipLoader } from "react-spinners";
import { Layout, Dropdown, Button, Menu } from "antd";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import SignIn from "./Auth/SignIn";
import Main from "./Dashboard/Main";
import Telemetry from "./Telemetry/Telemetry";
import logo from "./logo-image.png";

const { Header } = Layout;

class App extends Component {
  state = {
    loading: true,
    user: null,
  };

  componentDidMount = () => {
    const user = localStorage.getItem("@infleet-reports/user");

    this.setState({ user: JSON.parse(user), loading: false });
  };

  handleCurrentUser = (user) => {
    this.setState({ user });
  };

  menu = (username) => (
    <div className="menu">
      <p>
        Olá,
        <span>
          <strong> {username}</strong>
        </span>
      </p>
      <Button
        onClick={() => {
          localStorage.removeItem("@infleet-reports/user");
          this.setState({ user: null });
        }}
        type="danger"
        className="logout-button"
        icon={<LogoutOutlined />}
      >
        Sair
      </Button>
    </div>
  );

  render() {
    const { user, loading } = this.state;

    if (loading) {
      return (
        <div className="center-screen">
          <ClipLoader sizeUnit={"rem"} size={3} color={"#002140"} />
        </div>
      );
    }

    return user ? (
      <Router>
        <Layout>
          <Header style={{ backgroundColor: "#001132", padding: "0 16px" }}>
            <Grid fluid>
              <Row between="xs">
                <Col>
                  <div style={{ display: "flex" }}>
                    <div className="logoContainer">
                      <img className="logo" src={logo} alt="Logo" />
                      <span className="headerTitle">Relatórios</span>
                    </div>
                    <Menu
                      theme="dark"
                      mode="horizontal"
                      defaultSelectedKeys={["/"]}
                      // selectedKeys={[pathname]}
                      style={{ lineHeight: "64px", backgroundColor: "#001132" }}
                    >
                      <Menu.Item
                        style={{
                          backgroundColor: "#001132",
                          fontWeight: "bold",
                        }}
                        key="/"
                      >
                        <Link to="/">Resumo diário</Link>
                      </Menu.Item>

                      <Menu.Item
                        style={{
                          backgroundColor: "#001132",
                          fontWeight: "bold",
                        }}
                        key="/telemetry"
                      >
                        <Link to="/telemetry">Telemetria</Link>
                      </Menu.Item>
                    </Menu>
                  </div>
                </Col>
                <Col>
                  <Dropdown
                    className="header-menu"
                    overlay={this.menu(user.name)}
                    trigger={["click"]}
                    placement="bottomLeft"
                  >
                    <UserOutlined style={{ color: "white" }} type="user" />
                  </Dropdown>
                </Col>
              </Row>
            </Grid>
          </Header>
          <Switch>
            <Route exact path="/">
              <Main user={user} />
            </Route>
            <Route path="/telemetry">
              <Telemetry user={user} />
            </Route>
          </Switch>
        </Layout>
      </Router>
    ) : (
      <SignIn handleCurrentUser={this.handleCurrentUser} />
    );
  }
}

export default App;
