import { DownloadOutlined, PrinterOutlined } from "@ant-design/icons";
import { Button, DatePicker, notification, Select, Table } from "antd";
import moment from "moment";
import "moment/locale/pt-br";
import React, { useState, useEffect } from "react";
import ReactExport from "react-export-excel";
import PrintComponents from "react-print-components";
import api from "../services/api";
import "./dashboard.css";
import { calculateSummaryReport } from "./helpers/calculate-summary-report";
import { formatMillisecondsToReadableString } from "./helpers/format-milliseconds-to-readable-string";
import sumTotalDistance from "./helpers/sum-total-distance";

const { RangePicker } = DatePicker;
const { Option } = Select;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function (props) {
  const { user } = props;
  const [dates, setDates] = useState([]);
  const [hackValue, setHackValue] = useState();
  const [value, setValue] = useState();
  const [summaryReport, setSummaryReport] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [selectedVehiclePlate, setSelectedVehiclePlate] = useState(null);
  const [vehicles, setVehicles] = useState([]);

  const disabledDate = (current) => {
    if (!dates || dates.length === 0) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], "days") > 31;
    const tooEarly = dates[1] && dates[1].diff(current, "days") > 31;
    return tooEarly || tooLate;
  };

  const onOpenChange = (open) => {
    if (open) {
      setHackValue([]);
      setDates([]);
    } else {
      setHackValue(undefined);
    }
  };

  useEffect(() => {
    const fetchVehicles = async (userId) => {
      try {
        const response = await api.get(`/devices/${userId}`);

        if (response.status === 200) {
          setVehicles(response.data);
          setLoading(false);
        }
      } catch (e) {
        notification["error"]({
          message: "Erro ao buscar veículos",
          description: "Contate o suporte",
        });
      }
    };

    fetchVehicles(user.id);
  }, [user.id]);

  const generateReport = async () => {
    const formatStartDate = moment(dates[0]).startOf("day").toISOString();
    const formatFinishDate = moment(dates[1]).endOf("day").toISOString();

    setLoading(true);

    try {
      const response = await api.get("/one-month-summary", {
        params: {
          selectedVehicle: selectedVehicle,
          startDate: formatStartDate,
          finishDate: formatFinishDate,
        },
      });

      console.log(response);

      const summaryReport = calculateSummaryReport(response.data);

      setSummaryReport(summaryReport);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setSummaryReport([]);
      notification["error"]({
        message: "Erro gerar relatório",
        description:
          "Diminua o período de análise, tente novamente ou contate o suporte",
      });
    }
  };

  const handleVehicle = (value, vehicle) => {
    setSelectedVehicle(vehicle.props.id);
    setSelectedVehiclePlate(value);
  };

  const columns = [
    {
      title: "N°",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Data",
      dataIndex: "date",
      key: "date",
    },
    // {
    //   title: "Odômetro inicial (km)",
    //   dataIndex: "initialDistance",
    //   key: "initialDistance",
    // },
    // {
    //   title: "Odômetro final (km)",
    //   dataIndex: "finalDistance",
    //   key: "finalDistance",
    // },
    {
      title: "Distância percorrida (km)",
      dataIndex: "totalDistance",
      key: "totalDistance",
    },
    {
      title: "Velocidade média (km/h)",
      dataIndex: "meanSpeed",
      key: "meanSpeed",
      render: (text) => Math.round(text),
    },
    {
      title: "Velocidade máxima (km/h)",
      dataIndex: "maxSpeed",
      key: "maxSpeed",
    },
    {
      title: "Tempo acima da velocidade (90 km/h)",
      dataIndex: "countTimeOverspeed",
      key: "countTimeOverspeed",
    },
    // {
    //   title: "Horímetro inicial (h)",
    //   dataIndex: "initialHours",
    //   key: "initialHours",
    // },
    // {
    //   title: "Horímetro final (h)",
    //   dataIndex: "finalHours",
    //   key: "finalHours",
    // },
    {
      title: "Tempo em operação",
      dataIndex: "operationTime",
      key: "operationTime",
    },
  ];

  const formatStartDate = moment(dates[0]).format("DD/MM/YY");
  const formatFinishDate = moment(dates[1]).format("DD/MM/YY");

  const totalTime = formatMillisecondsToReadableString(
    summaryReport.reduce((a, b) => a + b.totalTime, 0)
  );

  const totalDistance = sumTotalDistance(summaryReport);

  return (
    <div className="dashboard-container">
      <h1>Relatório de Resumo</h1>
      <h3 style={{ color: "gray" }}>
        Neste relatório você poderá verificar indicadores gerais do dia-a-dia da
        operação num período específico
      </h3>
      <br />
      <p style={{ fontWeight: "bold" }}>
        Selecione o veículo e o período da análise
      </p>
      <Select
        placeholder="Selecione o veículo"
        size={"large"}
        showSearch
        onChange={handleVehicle}
        style={{ width: 300 }}
      >
        {vehicles.map((vehicle, index) => (
          <Option value={vehicle.name} id={vehicle.id} key={index}>
            {vehicle.name}
          </Option>
        ))}
      </Select>
      <br />
      <br />
      <RangePicker
        format="DD/MM/YY"
        size={"large"}
        value={hackValue || value}
        disabledDate={disabledDate}
        onCalendarChange={(val) => setDates(val)}
        onChange={(val) => setValue(val)}
        onOpenChange={onOpenChange}
      />
      <br />
      <button
        disabled={dates[0] ? false : true}
        onClick={generateReport}
        className="request-button"
      >
        Consultar
      </button>
      <div>
        <p>
          Total de horas do período: <strong>{totalTime}</strong>
        </p>
        <p>
          Total de distância no período: <strong>{totalDistance} km</strong>
        </p>
      </div>
      <div>
        {!!summaryReport.length && (
          <div style={{ float: "right", padding: "0 2rem" }}>
            <ExcelFile
              fileExtension="xlsx"
              filename={`Resumo [${formatStartDate} -> ${formatFinishDate}]`}
              element={
                <Button type="link" shape="round" icon={<DownloadOutlined />}>
                  Baixar
                </Button>
              }
            >
              <ExcelSheet data={summaryReport} name="Resumo">
                {columns.map(({ title, dataIndex, key }) => (
                  <ExcelColumn key={key} label={title} value={dataIndex} />
                ))}
              </ExcelSheet>
            </ExcelFile>
            <PrintComponents
              trigger={
                <Button type="link" shape="round" icon={<PrinterOutlined />}>
                  Imprimir
                </Button>
              }
            >
              <h1>
                {"Resumo de " + formatStartDate + " a " + formatFinishDate}
              </h1>
              <h2>
                <strong>{selectedVehiclePlate}</strong>
              </h2>
              <h3>Total de horas no período: {totalTime}</h3>
              <h3>Total de distância no período: {totalDistance}</h3>
              <Table
                rowKey="number"
                pagination={false}
                loading={loading}
                dataSource={summaryReport}
                columns={columns}
              />
            </PrintComponents>
          </div>
        )}
      </div>
      <div className="summary-container">
        <Table
          rowKey="number"
          pagination={false}
          loading={loading}
          dataSource={summaryReport}
          columns={columns}
        />
      </div>
    </div>
  );
}
