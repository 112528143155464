import { Position } from "../../types";
import { KNOTS_TO_KMH_UNIT } from "../../constants";

export const calculateMaxSpeed = (positions: Position[]) => {
  const maxSpeed = (
    Math.max(...positions.map((position: Position) => position.speed)) *
    KNOTS_TO_KMH_UNIT
  ).toFixed(2); // Multiply by 1.852 to transform knots into km/h;

  return maxSpeed;
};
