import {
  DownloadOutlined,
  EnvironmentFilled,
  PrinterOutlined,
} from "@ant-design/icons";
import { Button, DatePicker, notification, Table } from "antd";
import { find } from "lodash";
import moment from "moment";
import "moment/locale/pt-br";
import React, { Component } from "react";
import ReactExport from "react-export-excel";
import PrintComponents from "react-print-components";
import api from "../services/api";

const { RangePicker } = DatePicker;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class Main extends Component {
  state = {
    telemetryReport: [],
    loading: false,
    startDate: null,
    finishDate: null,
    vehicles: [],
  };

  componentDidMount = () => {
    const userId = this.props.user.id;
    this.fetchVehicles(userId);
  };

  generateReport = async () => {
    const { vehicles, startDate, finishDate } = this.state;

    const rightDate = moment(finishDate).add(1, "day").format();

    const vehiclesId = vehicles.map((el) => el.id).join(",");

    this.setState({ loading: true });

    const response = await api
      .get("/telemetry-report", {
        params: {
          vehicles: vehiclesId,
          startDate: startDate,
          finishDate: rightDate,
        },
      })
      .catch((e) => {
        if (e.response.status !== 200) {
          notification["error"]({
            message: "Erro ao retornar dados",
            description: "Por favor, contate o suporte",
          });
        }
        this.setState({ telemetryReport: [], loading: false });
      });

    if (response) {
      this.setState({ telemetryReport: response.data, loading: false });
    }
  };

  fetchVehicles = async (userId) => {
    const vehicles = await api.get(`/devices/${userId}`);

    this.setState({
      vehicles: vehicles.data,
      loading: false,
    });
  };

  handleVehicle = (value, vehicle) => {
    this.setState({
      selectedVehicle: vehicle.props.id,
    });
  };

  handleDate = (date, dateString) => {
    moment().add();
    const startDate = date[0].startOf("day").format();
    const finishDate = date[1].endOf("day").format();
    this.setState({ startDate, finishDate });
  };

  setAlarmType = (record) => {
    const json = JSON.parse(record.attributes).alarm;

    if (json === "hardCornering") return "Curva acentuada";

    if (json === "hardAcceleration") return "Aceleração brusca";

    if (json === "hardBraking") return "Frenagem brusca";

    return "unknown";
  };

  findVehicle = (record) => {
    const { vehicles } = this.state;

    const vehicle = find(vehicles, ["id", record.deviceid]);

    return vehicle.name;
  };

  columns = [
    {
      title: "N°",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Data",
      dataIndex: "fixtime",
      key: "fixtime",
    },
    {
      title: "Veículo",
      dataIndex: "vehicle",
      key: "vehicle",
    },
    {
      title: "Velocidade ( km / h )",
      dataIndex: "speed",
      key: "speed",
    },
    {
      title: "Tipo de alarme",
      dataIndex: "alarm",
      key: "alarm",
    },
    {
      title: "Ver no mapa",
      dataIndex: "map",
      key: "map",
      render: (text, record) => {
        const lat = record.latitude;
        const lon = record.longitude;
        const url =
          "http://www.google.com/maps?layer=c&cbll=" + lat + "," + lon;

        return <EnvironmentFilled onClick={() => window.open(url)} />;
      },
    },
  ];

  disabledDate = (current) => {
    return current && current < moment().endOf("day").subtract(2, "month");
  };

  render() {
    const { startDate, finishDate, telemetryReport, loading } = this.state;

    const telemetryReportData =
      telemetryReport.length &&
      telemetryReport
        .map((el, i) => ({
          ...el,
          fixtime: moment(el.fixtime).format("DD/MM/YY"),
          speed: Math.round(el.speed * 1.852),
          vehicle: this.findVehicle(el),
          alarm: this.setAlarmType(el),
          number: i + 1,
        }))
        .filter((event) => event.alarm !== "unknown");

    return (
      <div className="dashboard-container">
        <h1>Relatório de Telemetria</h1>
        <h3 style={{ color: "gray" }}>
          Neste relatório você poderá verificar as ocorrências relativas a{" "}
          <strong>Curvas acentuadas</strong>, <strong>Frenagens bruscas</strong>{" "}
          e <strong>Acelerações bruscas</strong>
        </h3>
        <br />
        <br />
        <p style={{ fontWeight: "bold" }}>Selecione o período da análise</p>
        <RangePicker
          format="DD/MM/YY"
          size={"large"}
          onChange={this.handleDate}
          disabledDate={this.disabledDate}
        />
        <br />
        <button
          disabled={startDate ? false : true}
          onClick={this.generateReport}
          className="request-button"
        >
          Consultar
        </button>
        <div>
          {!!telemetryReport.length && (
            <div style={{ float: "right", padding: "0 2rem" }}>
              <ExcelFile
                fileExtension="xlsx"
                filename={`Resumo ${startDate} -> ${finishDate}`}
                element={
                  <Button type="link" shape="round" icon={<DownloadOutlined />}>
                    Baixar
                  </Button>
                }
              >
                <ExcelSheet data={telemetryReportData} name="Resumo">
                  {this.columns.map(({ title, dataIndex, key }) => (
                    <ExcelColumn key={key} label={title} value={dataIndex} />
                  ))}
                </ExcelSheet>
              </ExcelFile>
              <PrintComponents
                trigger={
                  <Button type="link" shape="round" icon={<PrinterOutlined />}>
                    Imprimir
                  </Button>
                }
              >
                <h1>
                  {"Resumo de " + startDate + " a " + finishDate} |{" "}
                  <strong>{telemetryReport[0].device}</strong>
                </h1>

                <Table
                  rowKey="id"
                  pagination={false}
                  loading={loading}
                  dataSource={telemetryReportData}
                  columns={this.columns}
                />
              </PrintComponents>
            </div>
          )}
        </div>
        <div className="summary-container">
          <Table
            rowKey="id"
            pagination={false}
            loading={loading}
            dataSource={telemetryReportData}
            columns={this.columns}
          />
        </div>
      </div>
    );
  }
}
